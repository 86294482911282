import * as React from "react";
import { graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import Configuration from "../Configuration";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {
  CardList,
  TitledParagraph,
  PartnerLogoList,
  Button,
  Profile,
} from "@launchacademy/voyager";

import buildImage from "../services/buildImage";
import SyllabusModalButton from "../components/syllabus/SyllabusModalButton";
import { faChevronRight, faList } from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../components/contact/ContactForm";
import SyllabusRequestForm from "../components/syllabus/SyllabusRequestForm";

import "./css/about-launch-academy.css";

export const socialUrls = ["https://www.facebook.com", "https://github.com", "https://youtube.com"];

export const columns = 3;
export const content = [
  {
    id: "handsOnExpertise",
    imageData: {},
    header: "Hands-on expertise",
    body:
      "Your instructors are not only great teachers - they’re excellent developers as well. They regularly work on software projects to bring you the most cutting edge practices.",
  },
  {
    id: "evolvingCurriculum",
    imageData: {},
    header: "Evolving curriculum",
    body:
      "Technology doesn’t stay the same for long, and neither does our curriculum. The Launch Academy curriculum is updated every cohort to make sure you’re learning what is relevant and in demand.",
  },
  {
    id: "moreThanJustJob",
    imageData: {},
    header: "More than just a job",
    body:
      "Once you land your first software development job, the Launch community is still here for you. Ongoing access to curriculum, alumni communities, and other resources are available for life.",
  },
];

const AboutPage = ({ data, location }) => {
  const contentWithImages = content.map((content) => {
    return { ...content, Image: buildImage(data[content.id]) };
  });

  const hiringPartnerData = (data?.hiringPartnerSet?.featured_hiring_partners || []).map(
    (partner) => {
      return {
        name: partner.companyName,
        logoImage: (
          <GatsbyImage image={getImage(partner.logo.localFile)} alt={partner.companyName} />
        ),
      };
    },
  );

  console.log(hiringPartnerData);

  const teamProfiles = (data.teamMemberSet?.team_members || []).map((teamMember) => {
    return (
      <Profile
        name={`${teamMember.firstName} ${teamMember.lastName}`}
        Image={buildImage(teamMember.headshot?.localFile)}
        currentTitle={teamMember.jobTitle}
        profileType="teamMember"
      />
    );
  });

  return (
    <Layout>
      <Seo
        title="About Launch Academy"
        pathname={location.pathname}
        description="Learn about Boston's best coding bootcamp and software development school"
      />
      <section className="hero">
        <div className="hero__image-column">
          <div className="hero__image-container">
            <GatsbyImage image={getImage(data.studentsPairing)} className="hero__image" alt="" />
          </div>
        </div>
        <div className="hero__supporting-content">
          <h2 className="hero__image_subtitle">Beyond the bootcamp:</h2>
          <h1 className="hero__image_title">Empowering students to design their future</h1>
          <div className="hero__narrative">
            <p>
              At Launch Academy, you’ll learn more than just how to build incredible applications.
              We’ll teach you how to learn - giving you all the tools you need to continue your
              growth as a software developer well into the future. With lifelong career support,
              continued curriculum updates and access, and an alumni network 1000+ strong, you’ll
              have everything you need to pursue the future you want.{" "}
            </p>
          </div>
        </div>
      </section>

      <section className="bg-secondary-brand">
        <div className="constrained-container pl-12 pb-8 md:pb-16">
          <div className="hero__supporting-content px-4 xl:pl-24 py-8 xl:w-7/12">
            <h2 className="hero__image_title pb-10">
              Yes, we build immersive courses and career training. But where Launch excels is beyond
              the curriculum: setting up students for substantive success and continued support
            </h2>
            <div className="hero__narrative">
              <p>
                Launch Academy consists of a group of lifelong learners who are interested in
                supporting others to become better versions of themselves. We were founded with the
                aspiration of giving people the gift of loving what they do for work on a daily
                basis. Our passion for helping aspiring software engineers is as strong today as it
                was on May 1, 2013 on day one of our first cohort.{" "}
              </p>
            </div>
          </div>
          <section className="card-list-container px-4 xl:pl-48">
            <CardList content={contentWithImages} numberOfColumns={columns} />
          </section>
        </div>
      </section>

      <div className="constrained-container">
        <TitledParagraph title="We’re great at what we do"></TitledParagraph>
        <PartnerLogoList className="mb-16 md:mb-40" partnerData={hiringPartnerData} />
        <section className="max-w-screen-lg mt-4 md:mt-16 mx-auto">
          <div className="constrained-container">
            <div>
              <h2 className="py-10 text-center">
                “I looked at other boot camps and felt that Launch Academy had the most
                human-friendly approach.""
              </h2>
              <div className="grid grid-cols-2">
                <div className="text-right">
                  <GatsbyImage
                    image={getImage(data.susanMa)}
                    className="rounded-full"
                    alt="Susan Ma: Launch Academy Alumna"
                  />
                </div>
                <div className="ml-4">
                  <h6 className="font-bold font-headline leading-snug uppercase">Susan Ma</h6>
                  <h5 className="font-text text-base uppercase">StackBlitz</h5>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="hero constrained-container">
        <div className="col-span-12 lg:col-span-7 p-10">
          <h2 className="hero__image_title pb-10">
            Driven by a common thread to help others succeed
          </h2>
          <div className="hero__narrative_close">
            <p>
              Your success is our success. The Launch Academy team is here to help you level up and
              find the career you’ve always wanted.
            </p>
            <div className="hero__button-container mt-10">
              <Button placement="imageUrl" text="Meet The Team" to="/team" icon={faList} />
            </div>
          </div>
        </div>
        <div className="col-span-12">
          <div className="profile-list">{teamProfiles}</div>
        </div>
      </section>

      <section className="bg-secondary-brand">
        <div className="constrained-container hero">
          <div className="hero__supporting-content p-10">
            <h2 className="hero__image_title pb-10">
              Sign up today and get $1000 off your full tuition when you complete our next pre-work
              session.
            </h2>
            <div className="hero__button-container">
              <Button
                to={Configuration.applicationBaseUrl}
                text="Apply Today"
                icon={faChevronRight}
                className="banner__button"
                size="lg"
              />
            </div>
            <div className="hero__button-container">
              <SyllabusModalButton
                location={location}
                size="lg"
                className="banner__button button_secondary"
                text="Get the Syllabus"
                icon={faChevronRight}
              />
            </div>
          </div>
          <div className="hero__image-column">
            <div className="hero__image-container">
              <GatsbyImage
                image={getImage(data.studentsHangingOut)}
                className="hero__image"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <ContactForm className="hidden" />
      <SyllabusRequestForm className="hidden" />
    </Layout>
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query {
    studentsPairing: file(relativePath: { regex: "/about-us-page/studentsPairing.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    handsOnExpertise: file(relativePath: { regex: "/about-us-page/handsOnExpertise.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    evolvingCurriculum: file(relativePath: { regex: "/about-us-page/evolvingCurriculum.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    moreThanJustJob: file(relativePath: { regex: "/about-us-page/moreThanJustJob.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    corinneProfileImage: file(relativePath: { regex: "/about-us-page/corinneProfileImage.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    kerrinProfileImage: file(relativePath: { regex: "/about-us-page/kerrinProfileImage.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    nickProfileImage: file(relativePath: { regex: "/about-us-page/nickProfileImage.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    studentsHangingOut: file(relativePath: { regex: "/about-us-page/studentsHangingOut.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    susanMa: file(relativePath: { regex: "/susan-ma.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 50)
      }
    }
    teamMemberSet: strapiTeamSet(name: { eq: "about" }) {
      team_members {
        bio
        headshot {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FIXED
                width: 300
                height: 300
                transformOptions: { grayscale: true }
              )
            }
          }
        }
        jobTitle
        lastName
        firstName
      }
    }
    hiringPartnerSet: strapiFeaturedHiringPartnerSet(name: { eq: "about" }) {
      featured_hiring_partners {
        companyName
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(breakpoints: [190])
            }
          }
        }
      }
    }
  }
`;
